<template>
  <div id="cms-categories">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Categories</h2></b-col>
        <b-col>
          <router-link class="link-color" :to="{ name: 'CmsCategoryCreate' }" @click.prevent>
            <b-button type="submit" variant="success">Add Category</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="category-table">
            <b-table striped :fields="fields" :items="categories" style="color: #CBA661" head-variant="light">
              <template v-slot:cell(id)="id">
                <b-button-group size="small">
                  <router-link class="link-color" :to="{ name: 'CmsCategoryShow', params: { id: id.value }}" @click.prevent>
                    <b-icon icon="search" style="margin-right: 1em; color: #CBA661"></b-icon>
                  </router-link>
                  <router-link class="link-color" :to="{ name: 'CmsCategoryUpdate', params: { id: id.value }}" @click.prevent>
                    <b-icon icon="pencil" style="margin-right: 1em; color: #CBA661"></b-icon>
                  </router-link>
                  <router-link class="link-color" :to="{ name: 'CmsCategoryDelete', params: { id: id.value }}" @click.prevent>
                    <b-icon icon="x" style="margin-right: 1em; color: #CBA661"></b-icon>
                  </router-link>
                </b-button-group>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'

  export default {
    data() {
      return {
        loading: true,
        categories: [],
        fields: [
          {
            key: 'name',
            label: 'Name'
          },
          {
            key: 'name_nl',
            label: 'Dutch name',
          },
          {
            key: 'id',
            label: 'Actions',
          },
        ]
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get('https://batavia-backend.herokuapp.com/api/category')
          .then(response => (
              this.categories = response.data
          ))
          .finally(() => {
            this.loading = false
          })
    }
  }
</script>

<style scoped>
  .category-table {
    overflow: auto;
    height: 400px;
  }
</style>